#home {
  flex: 1;
  background-image: url('./Dark_background.svg');
  background-repeat: no-repeat;
  background-attachment: fixed; 
  background-size: cover;
  padding: 2vw;
}

#top_row {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 4vw;
}

#title {
  text-align: center;
  font-family: 'Lobster', Georgia Bold Italic, serif, sans-serif;
  font-size: 40pt;
  color:rgba(255, 255, 255);
  text-decoration: none;
  margin: 0 auto;
}

#title p {
  margin: 0;
}

#links {
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  right: 0px;
}

#links_linkedin {
  background-image: url("./iconmonstr-linkedin-3.svg");
  background-size: cover;
  width: 30px;
  height: 30px;
  margin-right: 16px;
  transition: filter 250ms;
}

/* mouse over link */
#links_linkedin:hover {
  filter: invert(75%);
}

#links_github {
  background-image: url("./iconmonstr-github-1.svg");
  background-size: cover;
  width: 30px;
  height: 30px;
  transition: filter 250ms;
}

/* mouse over link */
#links_github:hover {
  filter: invert(75%);
}


#menu {
  position: relative;
  width: 90px;
  height: 90px;
  background: black;
  border-radius: 16px;
  box-shadow: 5px 5px 15px 0px rgba(0,0,0,0.40);
}

#doublem, #hamburger {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 1;
  transition: opacity .5s;
}

.invisible {
  opacity: 0 !important;
}

#home #grid {
  display: grid;
  grid-template-columns: 90px 400px;
  grid-gap: 25px;
}

.pets-button {
  appearance: none;
  border: none;
  background: transparent;
  padding: 0px;
}

#credit {
  position: absolute;
  bottom: 1vw;
  right: 2.5vw;
  font-family: 'Lobster', Georgia Bold Italic, serif, sans-serif;
  /* font-family: Helvetica, Arial, sans-serif; */
  font-size: 12pt;
  color:rgba(255, 255, 255, 0.75);
  text-decoration: none;
}

/* unvisited link */
#credit a:link {
  color: rgba(255, 255, 255, 0.75);
  transition: filter 250ms;
  text-decoration: none;
}

/* visited link */
#credit a:visited {
  color: rgba(255, 255, 255, 0.75);
  text-decoration: none;
}

/* mouse over link */
#credit a:hover {
  filter: invert(75%);
  text-decoration: none;
}

/* selected link */
#credit a:active {
  color: rgba(9, 9, 9, 0.4);
  text-decoration: none;
}