.photos-container {
  width: 300px;
  height: 300px;
  position: relative;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 3px 3px 40px 1px rgba(0,0,0,0.47);
}

.photos-container.large {
  width: 400px;
  height: 400px;
  transition: transform 1s;
  margin-left: 2vw;
}

.photos-container.large:hover {
  transform: scale(1.1);
}

.photos-container > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0px;
  left: 0px;
  opacity: 1;
  transition: opacity 500ms;
}