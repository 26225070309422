#pets {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px 0px;
}

#pets #pets-grid {
  display: grid;
  grid-template-columns: repeat(3, 300px);
  grid-template-rows: repeat(2, 300px);
  grid-gap: 25px;
}

@media only screen and (max-width: 1120px) {
  #pets #pets-grid {
    grid-template-columns: repeat(3, 300px);
    grid-template-rows: repeat(2, 300px);
  }
}

@media only screen and (max-width: 1024px) {
  #pets #pets-grid {
    grid-template-columns: repeat(3, 300px);
    grid-template-rows: repeat(2, 300px);
  }
}

@media only screen and (max-width: 900px) {
  #pets #pets-grid {
    grid-template-columns: repeat(2, 300px);
    grid-template-rows: repeat(3, 300px);
  }
}

@media only screen and (max-width: 700px) {
  #pets #pets-grid {
    grid-template-columns: 300px;
    grid-template-rows: repeat(6, 300px);
  }
}