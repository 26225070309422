* {
  box-sizing: border-box;
}

html {
  overflow: hidden;
  min-height: 100vh;
}


body, #root {
  margin: 0;
  min-height: calc(100vh - 24px);
  overflow: auto;
  border: 12px solid white;
}

#root {
  display: flex;
}

button {
  cursor: pointer;
}